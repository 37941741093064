import React, { useEffect } from 'react';
import car from '../../assets/Car.png';
import driverlogo from '../../assets/driverlogo.png';
import './DriverContainer.scss';

const DriverContainer = () => {
    const handleDriverDownload = () => {
        if (window.innerWidth > 900) {
            window.location =
                'https://play.google.com/store/apps/details?id=com.drifedriverdubai';
            return;
        }

        window.location =
            'https://play.google.com/store/apps/details?id=com.drifedriverdubai';
    };

    useEffect(() => {
        document.title = 'Driver Container';
    }, []);

    return (
        <div className="driverDownload">
            <div className="background">
                <div className="section">
                    <div className="container">
                        <div className="information">
                            <div className="sentInfo">
                                <div className="sentInfoText" />
                                <div className="sentInfoText" />
                            </div>
                        </div>
                        <div className="card">
                            <img className="Image" src={car} alt="car" />
                            <div className="title" style={{ color: '#11ADDF' }}>
                                Download DRIFE now
                            </div>
                            <div className="subTitleConatiner">
                                <div className="subTitle">
                                    You have been referred by your friend to download the DRIFE
                                    App
                                </div>
                            </div>
                            <div className="downloadContainer">
                                <div className="driverContainer">
                                    <div>
                                        <img className="driver" src={driverlogo} alt="driver" />
                                    </div>
                                    <div
                                        className="buttonContainer"
                                        role="button"
                                        tabIndex={-1}
                                        onClick={handleDriverDownload}
                                        onKeyDown={handleDriverDownload}
                                    >
                                        <div className="button">Download Driver App</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            copyright 2024 Drife Technology Pvt. Ltd.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriverContainer;
